import './App.css';
import {Navbar} from 'react-bootstrap';
import Category from "./Category";
import ONDC from "./ONDC";
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const categories = [
  {
    "id": 1,
    "name": "Software Design",
    "description": "Software design and architecture",
    "image": "https://images.pexels.com/photos/5836/yellow-metal-design-decoration.jpg?auto=compress&cs=tinysrgb&dpr=2&w=500"
  },
  {
    "id": 2,
    "name": "Backend Development",
    "description": "Build scalable backend systems",
    "image": "https://images.pexels.com/photos/34676/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&w=500"
  },
  {
    "id": 3,
    "name": "Infrastructure",
    "description": "Infrastructure provisioning, automation and maintenance",
    "image": "https://images.pexels.com/photos/546819/pexels-photo-546819.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
  }

];

function App() {
  return (
    <div className="App">
      <Navbar bg="primary">
        <Navbar.Brand href="/" style={{marginLeft: "10px", color: "#fff"}}><b>Viranc Infotech</b></Navbar.Brand>
        <div className="collapse navbar-collapse justify-content-end" id="navbarCollapse">
          <ul className="navbar-nav">
            <li className="nav-item">
              {/*<div>*/}
              {/*  <button className="btn btn-light-custom btn-sm" style={{marginRight: "5px", color: "#fff"}}>Contact Us*/}
              {/*  </button>*/}
              {/*</div>*/}
            </li>
          </ul>
        </div>
      </Navbar>
      <main>
        <br/>
        <h6 style={{textAlign: "left", margin: "10px"}}>Viranc Infotech is a software development firm providing software design, development and consultancy services. It also includes a research arm which focusses on Cutting-Edge Technologies.</h6>
        <br/>
        <Category categories={categories} />
        <BrowserRouter>
          <Routes>
            <Route path="/ondc" element={<ONDC />}/>
          </Routes>  
        </BrowserRouter>
        <br/>
        <br/>
        <h6>Contact: info @ viranc.com</h6>

      </main>
    </div>
  );
}

export default App;
