import React, {Component} from 'react';

import {Card, CardGroup} from 'react-bootstrap';

export default class Category extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {categories} = this.props;
        return (
            <CardGroup>
                {categories.map((category) => (
                        <Card key={category.id}>
                            <Card.Img variant="top" src={category.image}/>
                            <Card.Body>
                                <Card.Title>
                                    {category.name}
                                </Card.Title>
                                <Card.Text>
                                    {category.description}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    )
                )}
            </CardGroup>
        )
    }
}