import React, { Component } from 'react';
export default class ONDC extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{ textAlign: "left", margin: "10px", wordWrap: "break-word" }}>
                <br/>
                <h5>ONDC Resources / API / Documentation</h5>

                <br/>
                <h6>Open Network for Digital Commerce (ONDC) is the first-of-its-kind initiative globally to pave the way for reimagining digital commerce in India</h6>
                <br/>
                <p>Seller Product (Retail Domain Seller and Logistics Domain Buyer) - Contact: info @ viranc.com </p>

                <p>ONDC Website - <a href="https://ondc.org/" target="_blank">https://ondc.org/</a></p>

                <p>ONDC Strategy Paper - <a href="https://ondc.org/image/ONDCStrategyPaper.pdf" target="_blank">https://ondc.org/image/ONDCStrategyPaper.pdf</a></p>

                <p>ONDC Blog - <a href="https://ondc.org/blog/" target="_blank">https://ondc.org/blog/</a></p>

                <p>ONDC Twitter - <a href="https://twitter.com/ONDC_Official" target="_blank">https://twitter.com/ONDC_Official</a></p>

                <p>ONDC Github - <a href="https://github.com/ONDC-Official" target="_blank">https://github.com/ONDC-Official</a></p>

                <p>ONDC Youtube Channel - <a href="https://www.youtube.com/channel/UC91pPxvCvn0CiXiKLPBeqzg/videos" target="_blank">https://www.youtube.com/channel/UC91pPxvCvn0CiXiKLPBeqzg/videos</a></p>

                <p>ONDC Quickstart Guide - <a href="https://github.com/ONDC-Official/developer-docs/blob/main/Tech_Quickstart_Guide.md" target="_blank">https://github.com/ONDC-Official/developer-docs/blob/main/Tech_Quickstart_Guide.md</a></p>

                <p>Developing an App for ONDC - <a href="https://docs.google.com/presentation/d/1OYVDmy58wB6jDvSJaxVfc5JwRG5rYMtmhqt7oG9Cp0Y/" target="_blank">https://docs.google.com/presentation/d/1OYVDmy58wB6jDvSJaxVfc5JwRG5rYMtmhqt7oG9Cp0Y/</a></p>

                <p>ONDC Use cases - <a href="https://docs.google.com/spreadsheets/d/18_YqkIXjJyVOA0ZXhJlQ80t0qaN9k9brKHEKp1CIB4c/" target="_blank">https://docs.google.com/spreadsheets/d/18_YqkIXjJyVOA0ZXhJlQ80t0qaN9k9brKHEKp1CIB4c/</a></p>

                <p>ONDC Developer Docs - <a href="https://github.com/ONDC-Official/developer-docs" target="_blank">https://github.com/ONDC-Official/developer-docs</a></p>

                <p>ONDC Protocol Open API Specifications - <a href="https://github.com/ONDC-Official/ONDC-Protocol-Specs" target="_blank">https://github.com/ONDC-Official/ONDC-Protocol-Specs</a></p>
                
                <p>ONDC Protocol Specifications Swaggerhub - <a href="https://app.swaggerhub.com/organizations/ONDC" target="_blank">https://app.swaggerhub.com/organizations/ONDC</a></p>
                
                <p>ONDC Integration Guide - <a href="https://docs.google.com/presentation/d/1HPRXk3lVYKmyAFcApgukZuwHhIZ_VlqR/" target="_blank">https://docs.google.com/presentation/d/1HPRXk3lVYKmyAFcApgukZuwHhIZ_VlqR/</a></p>
                
                <p>ONDC Beta Testing Apps - <a href="https://ondc.org/blog/experience-and-shape-ondcs-new-e-commerce-revolution/" target="_blank">https://ondc.org/blog/experience-and-shape-ondcs-new-e-commerce-revolution/</a></p>
                

                <br/>
                <h6>ONDC Adaptor interfaces are the open APIs developed based on the open-source interoperable specification of Beckn protocol. Hence to understand it better you can use the resources created by the Beckn protocol team.</h6>
                <br/>

                <p>Beckn Protocol Introduction - <a href="https://developers.becknprotocol.io/docs/introduction/introduction/" target="_blank">https://developers.becknprotocol.io/docs/introduction/introduction/</a></p>

                <p>Beckn Protocol Specification - <a href="https://developers.becknprotocol.io/docs/introduction/beckn-protocol-specification/" target="_blank">https://developers.becknprotocol.io/docs/introduction/beckn-protocol-specification/</a></p>

                <p>Registry Infrastructure - <a href="https://developers.becknprotocol.io/docs/introduction/the-registry-infrastructure/" target="_blank">https://developers.becknprotocol.io/docs/introduction/the-registry-infrastructure/</a></p>

                <p>Transaction Layer - <a href="https://developers.becknprotocol.io/docs/introduction/transactional-layer/" target="_blank">https://developers.becknprotocol.io/docs/introduction/transactional-layer/</a></p>

                <p>Certification Layer - <a href="https://developers.becknprotocol.io/docs/introduction/certification-layer/" target="_blank">https://developers.becknprotocol.io/docs/introduction/certification-layer/</a></p>

                <p>Video Overview - <a href="https://developers.becknprotocol.io/docs/introduction/video-overview/" target="_blank">https://developers.becknprotocol.io/docs/introduction/video-overview/</a></p>

                <p>Beckn Github Repo - <a href="https://github.com/beckn" target="_blank">https://github.com/beckn</a></p>

                <p>Beckn Protocol Communication - <a href="https://github.com/beckn/protocol-specifications/blob/master/docs/protocol-drafts/BECKN-RFC-003-Beckn-Protocol-Communication-Draft-01.md" target="_blank">https://github.com/beckn/protocol-specifications/blob/master/docs/protocol-drafts/BECKN-RFC-003-Beckn-Protocol-Communication-Draft-01.md</a></p>

                <p>Beckn Protocol Drafts - <a href="https://github.com/beckn/protocol-specifications/tree/b028fa3b8a5ea3c9be890ae673fa4472a4e78355/docs/protocol-drafts" target="_blank">https://github.com/beckn/protocol-specifications/tree/b028fa3b8a5ea3c9be890ae673fa4472a4e78355/docs/protocol-drafts</a></p>

                <p>Beckn Youtube Channel - <a href="https://www.youtube.com/channel/UCbobVB4i_chta5GXEyh9q7Q/videos" target="_blank">https://www.youtube.com/channel/UCbobVB4i_chta5GXEyh9q7Q/videos</a></p>

                <p>Beckn Specification Reference - <a href="https://developers.becknprotocol.io/docs/core-specification/" target="_blank">https://developers.becknprotocol.io/docs/core-specification/</a></p>

                <p>Beckn Core APIs - <a href="https://developers.becknprotocol.io/docs/core-specification/core-apis/" target="_blank">https://developers.becknprotocol.io/docs/core-specification/core-apis/</a></p>

                <p>Beckn Schema Reference - <a href="https://developers.becknprotocol.io/docs/core-specification/schema-reference/" target="_blank">https://developers.becknprotocol.io/docs/core-specification/schema-reference/</a></p>

                <p>Beckn Ecommerce Transaction Flows - <a href="https://drive.google.com/file/d/1wxlhzdaQ6cytltIrXYm6pWJ9UPdbFZPO/view" target="_blank">https://drive.google.com/file/d/1wxlhzdaQ6cytltIrXYm6pWJ9UPdbFZPO/view</a></p>
                
                <br/>

            </div>
        )
    }
}
